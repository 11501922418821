export const STRIPE_PRICE_OPTIONS = {
  PERSONAL: { price: "$0", stripe_id: "" },
  PROFESSIONAL_FREE: { price: "$0", stripe_id: "price_1Myhi4CfiK63VCYr4YJKXcsH" },
  PROFESSIONAL_QUARTERLY: { price: "$9", stripe_id: "price_1MwDugCfiK63VCYryWeIby71" },
  PROFESSIONAL_YEARLY: { price: "$27", stripe_id: "price_1Mzm2oCfiK63VCYrNyBZBnHf" },
  TEAM_QUARTERLY: { price: "$15", stripe_id: "" },
  TEAM_YEARLY: { price: "$45", stripe_id: "" },
};

/* Dictates what kind of calendars you can run reports on. It must be set to one of the 3 Google options:
    - Google: calendar.accessRole: ["owner", "writer", "reader"]
    - Microsoft:  -calendar.owner: {name: "name", address: "name@email.com"}
                  -calendar.canEdit: boolean 
   Note: For Microsoft, it's possible to be an owner of a calendar, and have canEdit set to false.
         e.g.  The default United States holidays calendar and the Birthdays calendar.
               You're the owner of these calendars, but you can't edit them. */
export const ALLOWED_ROLE_OPTIONS = {
  OWNER: "owner",
  WRITER: "writer",
  READER: "reader",
};
/* If set to   READER: You can read all calendars.
               WRITER: You can read calendars you can write to, and own.
               OWNER: You can only read calendars you own. */
export const ALLOWED_ROLE = ALLOWED_ROLE_OPTIONS.READER;

/* Valid report range */
export const MIN_RANGE_IN_DAYS = 1;
export const MAX_RANGE_IN_DAYS = 92;
// Number of previous quarters/months to display for the report date range input
export const NUMBER_OF_QUARTERS = 2;
export const NUMBER_OF_MONTHS = 3;

// Probability that confetti will show when a Report loads
export const CHANCE_OF_CELEBRATION = 0.2; // 0.2 = 20%
export const NEW_REPORT_MIN_LOADING_TIME_IN_MS = process.env.NODE_ENV === "development" ? 2000 : 4000;
export const TOAST_DISPLAY_DURATION = 2000;
export const TOAST_ERROR_DISPLAY_DURATION = 4000;

export const COLORS = {
  primary: "#773EF2", // bg-violet-500
  secondary: "#A78BFA", // bg-violet-400
  gray: "#F1F5F9", // bg-slate-100
  dark_gray: "#E5E7EB", // bg-slate-200
  blue: "#0EA5E9", // bg-sky-500
  green: "#22C55E", // bg-green-500
  yellow: "#EAB308", // bg-yellow-500
  red: "#EF4444", // bg-red-500
};

/* % value within which the metric will be categorized as "close to target".
   e.g. If the "on target" threshold is 20%, and this value is set to 20, then
   if the value >= 16 and < 20 it will be categorized as "close to target". */
export const STATUS_ON_TARGET_PROXIMITY = 20;

/* Dictates to what number of decimal points all stat/percentage values are rounded. */
export const STAT_DECIMAL_POINTS = 1;
export const PERC_DECIMAL_POINTS = 0;
export const INTEGER = 0;

/* Dictates above how many chars the labels get truncated for the treemaps. */
export const TREEMAP_STRING_TRUNC = 24;

/* Determines TTL for event caching. Once events are fetched via API and stored in Firestore,
   they will be cached for this amount of time, meaning we will not hit the API again until
   this amount of time has passed. Default is 1 hour. */
export const TIME_TO_LIVE = 60 * 60 * 1000; // 60 mins
// export const TIME_TO_LIVE = 10000; // 10 seconds

/* Determines the expiration for access tokens saved session storage. They expire in 60 mins,
   so we give ourselves a little bit of room and set the expiration at 50 mins. */
export const TOKEN_EXPIRATION = 50 * 60 * 1000; // 50 mins
// export const TOKEN_EXPIRATION = 10000; // 10 seconds
