import { db } from "../config/firebaseConfig";
import {
  Timestamp,
  collection,
  query,
  where,
  doc,
  getDoc,
  getDocs,
  getCountFromServer,
  orderBy,
} from "firebase/firestore";
import {
  TREEMAP_STRING_TRUNC,
  STAT_DECIMAL_POINTS,
  STATUS_ON_TARGET_PROXIMITY,
  STRIPE_PRICE_OPTIONS,
  TIME_TO_LIVE,
} from "../config/settings.js";
import { STATUS_TYPES, PRICE_TERMS, PRICE_TIERS, REPORT_RANGE_OPTIONS } from "../config/options.js";
import { getDateStringLocalized } from "./collectionUtil";

/* Returns the target status (on target, close to target, off target) for a metric. */
export function getTargetStatus(actual, target, isReversed) {
  if (isReversed) {
    if (actual >= target) return STATUS_TYPES.ON_TARGET;
    else if (actual >= target * (1 + STATUS_ON_TARGET_PROXIMITY / 100)) return STATUS_TYPES.CLOSE_TO_TARGET;
    else return STATUS_TYPES.OFF_TARGET;
  } else {
    if (actual <= target) return STATUS_TYPES.ON_TARGET;
    else if (actual <= target * (1 + STATUS_ON_TARGET_PROXIMITY / 100)) return STATUS_TYPES.CLOSE_TO_TARGET;
    else return STATUS_TYPES.OFF_TARGET;
  }
}

/* Formats numbers for displaying.
   Takes the number and the number of decimals.
   Rounds up to that number of decimals. e.g.
   number = 2.666666, decimal = 1
    => 2.7
   number = 2, decimal = 1
    => 2 */
export function dpFormat(number, decimal) {
  if (isNaN(number)) {
    return "0";
  } else {
    return Number.isInteger(number) ? Math.round(number) : number.toFixed(decimal);
  }
}

/* Format dates for displaying.
   Displays the date string in locale format.
   In US, this would be something like "" */
export function dpDate(dateObj) {
  const date = new Date(dateObj);
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

/* Format for treemap chart ingestion. Needs to be in this format:
    [{x: "shaun", y: 22.5}, ...]

    It takes as input 2 arrays of equal length, each for x and y.
  */
export function getTreemap(xArray, yArray) {
  const formattedTreemap = [];
  xArray.forEach((value, index) => {
    let text = value;
    if (value.length > TREEMAP_STRING_TRUNC) {
      text = text.substring(0, TREEMAP_STRING_TRUNC) + "...";
    }
    formattedTreemap.push({ x: text, y: yArray[index].toFixed(STAT_DECIMAL_POINTS) });
  });

  if (formattedTreemap.length === 0) {
    /* Returning an empty array of length 0 will break treemaps. If no qualified events exist, 
       return an structurally valid empty array to be ingested by the treemap. */
    return [{ x: "", y: 0 }];
  } else {
    return formattedTreemap;
  }
}

/* When you retrieve a Firestore timestamp using snapshot.data(), it returns a Timestamp object. 
   You can convert this object to a JavaScript Date object using the toDate() method provided by 
   the Firestore library. If you want to mimic this Timestamp object by writing out the structure
   of a Timestamp object (which is the case for sample report) then you have to first convert it 
   into a Timestamp object then perform toDate() on it.
   
   This function handles all conversions of Firestore Timestamp objects.*/
export function convertFirebaseDate(date) {
  let convertedDate = null;
  try {
    convertedDate = date.toDate();
  } catch (error) {
    if (error instanceof TypeError) {
      const firebaseTimestamp = new Timestamp(date.seconds, date.nanoseconds);
      convertedDate = firebaseTimestamp.toDate();
    }
  }
  return convertedDate;
}

/* Given the currently logged in user, return the provider name: Google or Microsoft */
export function getProvider(user) {
  return user.providerData[0].providerId;
}

/* Takes an array of Microsoft calendar list and translates them into
   the Google's calendar list format. Only translate what's needed. 
   
   Google doc: https://developers.google.com/calendar/api/v3/reference/calendarList#resource
   Microsoft doc: https://learn.microsoft.com/en-us/graph/api/user-list-calendars?view=graph-rest-1.0 */
export function translateCalList(microsoftCalList) {
  const googleCalList = microsoftCalList.map((microsoftObject) => {
    const googleObject = {
      kind: "calendar#calendarListEntry",
      id: microsoftObject.id,
      summary: microsoftObject.name,
      primary: microsoftObject.isDefaultCalendar,
    };
    return googleObject;
  });
  return googleCalList;
}

/* Returns the browser's time zone setting in IANA format. */
export function getBrowserTimeZone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/* Takes an array of Microsoft calendar events and translates them into
   the Google's calendar event format. Only translate what's needed. 
   
   Google doc: https://developers.google.com/calendar/api/v3/reference/events
   Microsoft doc: https://learn.microsoft.com/en-us/graph/api/resources/event?view=graph-rest-1.0 */
export function translateCalEvents(microsoftCalEvents, userEmail) {
  const googleCalEvents = [];

  microsoftCalEvents.forEach((event) => {
    /* id: 
        -Google: id: string
        -Microsoft: id: string
       They have different allowed character sets so Microsoft id is guaranteed to be unique when transformed.
       See documentation for details. */
    const id = event["id"];

    /* iCalUId: Event unique identifier as defined in RFC5545. It is used to uniquely identify events accross calendaring systems.
        -Google: iCalUID: string
        -Microsoft: iCalUId: string 
          - Note that "D" is capitalized for Google, whereas it's smallcase for Microsoft. */
    const iCalUID = event["iCalUId"];

    /* kind:
        -Google: kind: string
          - Only events are supported. Tasks and reminders are all excluded.
        -Microsoft: n/a
          - They are all calendar events. No need to filter. */
    const kind = "calendar#event";

    /* responseStatus:
        -Google: attendees[].responseStatus: string
        -"needsAction", "declined", "tentative", "accepted"
       -Microsoft: responseStatus.response: string
        -"notResponded", "declined", "tentativelyAccepted", "accepted", "organizer", "none"
        -"none" == "notResponded" See documentation for explanation */
    const responseStatus = getResponseStatus(event["responseStatus"]["response"]);

    /* summary:
        -Google: summary: string
        -Microsoft: subject: string */
    const summary = event["subject"];

    /* description:
        -Google: description: string
          - Supports HTML
        -Microsoft: body.content: string 
          - body.contentType can be "text" or "html" but we don't need to transform this value.*/
    const description = event["body"]["content"];

    /* start:
        -Google: start {
          "date": date,         => all-day event. ISO 8601 format e.g. "2023-03-21"
          "dateTime": datetime, => normal event. ISO 8601 format e.g. "2023-03-20T10:00:00-04:00"
          "timeZone": string,   => "The time zone in which the time is specified. 
                                    (Formatted as an IANA Time Zone Database name, e.g. "Europe/Zurich".) 
                                    For recurring events this field is required and specifies the time zone 
                                    in which the recurrence is expanded. For single events this field is 
                                    optional and indicates a custom time zone for the event start/end."
        } 
        -Microsoft: start {
          dateTime: string,     => ISO 8601 format e.g. "2017-08-29T04:00:00.0000000"
          timeZone: string,     => "Represents a time zone, for example, "Pacific Standard Time"."
                                    These are Windows time zone values. You can see the entire list here: 
                                    https://learn.microsoft.com/en-us/windows-hardware/manufacture/desktop/default-time-zones?view=windows-11
                                    
                                    There's no need to translate the time zones because the Microsoft times 
                                    are already fetched using the user's Microsoft mailboxSettings timezone.
                                    So, all times should already be in the correct timezone. Search for "userTimezone"
                                    in the CreateReport component to see this in action.
                                    
                                    If the need should arise in the future, there's a handy package that does this
                                    for you: https://www.npmjs.com/package/windows-iana
        } 
          - The only other thing to note is Microsoft handles all day events differently: 
            - isAllDay: boolean => If yes, then set the "date" value for Google to any day, and don't set dateTime or timeZone.
                                    The event then will be disqualified in eventCollections. */
    const start = {};
    if (event.hasOwnProperty("isAllDay") && event.isAllDay) {
      start.date = getDateStringLocalized(new Date(event["start"]["dateTime"]));
    } else {
      start.dateTime = event["start"]["dateTime"];
      start.timeZone = event["start"]["timeZone"];
    }
    const end = {};
    if (event.hasOwnProperty("isAllDay") && event.isAllDay) {
      end.date = getDateStringLocalized(new Date(event["end"]["dateTime"]));
    } else {
      end.dateTime = event["end"]["dateTime"];
      end.timeZone = event["end"]["timeZone"];
    }

    /*--------------------------------------------------------------------------------------------------------------------------------------------
    #############################################################################
    ### General differences. These differences are good contextual knowledge. ###
    ### They likely don't impact the code too much, if at all.                ###
    #############################################################################
    ----------------------------------------------------------------------------------------------------------------------------------------------
    |                                          Google                                                        Microsoft                           |
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organizer object      - Always set.                                           - Always set.        
    property
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organizer can RSVP    - Yes, default = accepted.                              - No.

                          * We use the responseStatus as they are presented. 
                            Makes sense if you're not the organizer, of if you're the Google event organizer. The only exception is:
                            Microsoft event organizers, when converted to Google event, will be added as an attendee and always have 
                            responseStatus set to true.
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organizer display     - In the attendees list (right column).                 - In the organizer section (above the attendees list)
                          - In the main section (main section).
                            Note: If org = you, then your calendar name is 
                                            shown.
                                  If org = others, their email is shown.

                          * No impact to the code.
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organizer can be      - Yes, in which case they remain the organizer          - No.
    removed as attendee     in the main section, but removed from the 
                            attendees list.

                          * No impact to the code.
                            We don't care if they've been removed. We can still see the organizer in the object property.
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organizer can         - Yes, ownership can be transferred.                    - No.
    change               

                          * No impact to the code.
                            We don't care if it's been changed. We care about the current "organizer".
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Creator               - If you create an event, you are the creator and the   - No separate concept of "creator"
                            organizer. 
                          - If you then transfer ownership, you're still the 
                            creator but the organizer changes.
                          - If you create an event, add an attendee but then 
                            remove yourself, you will be the creator/organizer 
                            but not an attendee.

                          * No impact to the code.
                            We don't use this. We only use "organizer"
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Delegating            - You can give write permission to others. They can     - You can assign someone as a delegate. They can create,
                            create an event on your calendar and invite other       edit, delete etc on your behalf. It will seem like you are
                            people. They're the creator. You're the organizer.      preforming the actions.

                          * No impact to the code.
                            We don't use this. We use "organizer", and don't care if that's a delegate or not.
    ----------------------------------------------------------------------------------------------------------------------------------------------


    #################################################
    ### Organizer as attendee (0 other attendees) ###
    #################################################
    ----------------------------------------------------------------------------------------------------------------------------------------------
    |                                          Google                                                        Microsoft                           |
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organized by me       - No attendees list is shown.                           - No attendees list is shown.

                          * No impact to the code.
                            We don't do anything here. We show them as events with 0 attendees.
    ----------------------------------------------------------------------------------------------------------------------------------------------

    ################################################################
    ### Organizer as attendee (At least 1 other attendee exists) ###
    ################################################################
    ----------------------------------------------------------------------------------------------------------------------------------------------
    |                                          Google                                                        Microsoft                           |
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organized by me       - Organizer is an attendee.                             - Organizer is NOT an attendee.

                          * When converting Microsoft event to Google event, we add the organizer to the attendees list,
                            if and only if, there is at least 1 other attendee.
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organized by others   - Organizer is an attendee.                             - Organizer is an attendee.
                                                                                    Note: Only in the attendees property. In the UI it will
                                                                                    still be shown in a separate "organizer" section above
                                                                                    the attendees list.

                          * No impact to the code.
                            Organizer is already an attendee.
    ----------------------------------------------------------------------------------------------------------------------------------------------

    ###############################################
    ### Hide attendees flag is set by organizer ###
    ###############################################
    ----------------------------------------------------------------------------------------------------------------------------------------------
    |                                          Google                                                        Microsoft                           |
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organized by me       - You can see all attendees.                            - You can see all attendees.
                          - guestsCanSeeOtherGuests is set (false)                - hideAttendees is set (true).            

                          * No impact to the code.
                            To organizers, these are just like any other events.
                            Yes, you can tell if the flag is set in the object property, and also in Google UI with the "See guest list" checkbox, 
                            and in Microsoft UI with the message displayed above the attendees list: "You have hidden the list of attendees for 
                            this event." But knowing the hidden status does not help with anything for the organizers, for our purpose.

                            We already add the organizer as an attendee anyway, so no further action is needed.
    ----------------------------------------------------------------------------------------------------------------------------------------------
    Organized by others   - 1 attendee (you).                                     - 1 attendee (you).               
                          - Organizer can be seen in the main section.            - 1 organizer.
                          - guestsCanSeeOtherGuests is set (false)                - hideAttendees is NOT set (false).            
                            i.e. Message is displayed above the attendees list      i.e. No message is displayed to the attendees. From their
                                                                                         perspective, they may think it's a 1-on-1 meeting if
                                                                                         no other context is given.
                          
                          * No impact to the code.
                            Microsoft: hideAttendees = true will only ever be visible to the organizer. 
                            Microsoft: hideAttendees will be always false if you're not the organizer.

                            Google event will have 1 attendee in this instance. (Organizer info still available in the object)
                            Microsoft event, once converted to Google, will have 2 attendees in this instance. 

                            With Microsoft events, if you're not the organizer, you can't tell the difference between events with hidden
                            attendees and 1-on-1 meetings. (Unless you get clues from the title, description etc)
                            Therefore, Microsoft events can never have just 1 attendee. It will either be 0 or 2+.

                            Since hideAttendees only works for the Microsoft organizers, we don't convert this property over to Google.
    --------------------------------------------------------------------------------------------------------------------------------------------*/

    /* organizer:
        -Google: organizer: {
                    "id": string,
                    "email": string,
                    "displayName": string,
                    "self": boolean
                  },
        -Microsoft: organizer: {
                      "emailAddress": {
                        "address": string,
                        "name": string,
                      }
                    } */
    const organizer = {
      self: event["isOrganizer"],
      email: event["organizer"]["emailAddress"]["address"],
      displayName: event["organizer"]["emailAddress"]["name"],
    };

    /* attendees:
        -Google: "attendees": [
          {
            "id": string,
            "email": string,
            "displayName": string,
            "organizer": boolean,
            "self": boolean,
            "resource": boolean,
            "optional": boolean,
            "responseStatus": string,
            "comment": string,
            "additionalGuests": integer
          },
          ...
        ]
        -Microsoft: "attendees": [
          {
            "emailAddress":     {
                                  "address": "string",
                                  "name": "string"
                                }
            "proposedNewTime":  {
                                  "end": {"@odata.type": "microsoft.graph.dateTimeTimeZone"},
                                  "start": {"@odata.type": "microsoft.graph.dateTimeTimeZone"}
                                }
            "status":           {                           => Same as responseStatus above: 
                                  "responseStatus": {          "notResponded", "declined", "tentativelyAccepted", "accepted", "organizer", "none"
                                    "response": string,
                                    "time": DateTimeOffset,
                                },
            "type": "String"                                => The attendee types: "required", "optional", "resource"
          }, 
          ...
        ] */
    const attendees = [];
    event["attendees"].forEach((attendee) => {
      const googleAttendee = {
        email: attendee["emailAddress"]["address"],
        displayName: attendee["emailAddress"]["name"],
        responseStatus: getResponseStatus(attendee["status"]["response"]),
        resource: event["type"] && event["type"] === "resource" ? true : false,
        optional: event["type"] && event["type"] === "optional" ? true : false,
      };
      if (attendee["emailAddress"]["address"] === event["organizer"]["emailAddress"]["address"]) {
        googleAttendee.organizer = true;
      }
      if (attendee["emailAddress"]["address"] === userEmail) {
        googleAttendee.self = true;
      }
      attendees.push(googleAttendee);
    });

    /* Google automatically adds the organizer as an attendee, if there's at least 1 other attendee.
       Microsoft adds the organizer as an attendee for incoming invites (i.e. someone else organized the meeting and invited you.)
       Microsoft does NOT add you as and attendee if you organized the meeting.
       Check if the Microsoft event has at least 1 attendee, and also that you're the organizer. 
       If so, add the organizer as an attendee. */
    if (event["attendees"].length > 0 && event["isOrganizer"] === true) {
      attendees.push({
        email: event["organizer"]["emailAddress"]["address"],
        displayName: event["organizer"]["emailAddress"]["name"],
        responseStatus: "accepted",
        resource: false,
        optional: false,
        organizer: true,
        self: true,
      });
    }

    /* eventType:
        -Google: eventType: string
          -"default" - A regular event or not further specified.
          -"outOfOffice" - An out-of-office event.
          -"focusTime" - A focus-time event.
          -"workingLocation" - A working location event. (Google Workspace early preview)
        -Microsoft: showAs: string 
          -free, tentative, busy, oof, workingElsewhere, unknown. */
    let eventType = "";
    switch (event["showAs"]) {
      case "busy":
      case "tentative":
      case "workingElsewhere":
      case "unknown":
        eventType = "default";
        break;
      case "free":
        eventType = "focusTime";
        break;
      case "oof":
        eventType = "outOfOffice";
        break;
      default:
        eventType = "default";
    }

    const googleEvent = {
      id: id,
      iCalUID: iCalUID,
      kind: kind,
      summary: summary,
      description: description,
      start: start,
      end: end,
      attendees: attendees,
      organizer: organizer,
      eventType: eventType,
      _m_responseStatus: responseStatus,
    };
    googleCalEvents.push(googleEvent);
  });
  if (process.env.NODE_ENV === "development") {
    console.log("mapping from microsoftCalEvents: ", microsoftCalEvents);
    console.log("mapping to   googleCalEvents   : ", googleCalEvents);
  }
  return googleCalEvents;
}

/* Given a Microsoft responseStatus, returns the corresponding Google responseStatus */
function getResponseStatus(microsoftStatus) {
  switch (microsoftStatus) {
    case "accepted":
    case "organizer":
      return "accepted";

    case "tentativelyAccepted":
      return "tentative";

    case "declined":
      return "declined";

    case "notResponded":
    case "none":
      return "needsAction";
    default:
      return "accepted";
  }
}

/* Called right before the fetched calendar events are sent over to Firesotre. Perform final processing. */
export function finalizeCalendarResults(events) {
  /* Determine whether the event has an agenda or not. V1 simply takes the description field, and then stores the
     number of characters after doing some simple sanitizing. */
  events.forEach((event) => {
    if (event.hasOwnProperty("description")) {
      /* Remove HTML tags and white spaces from the description. */
      const sanitizedDescription = removeHtmlTags(event.description);
      /* Shorten the description string. */
      event.description = sanitizedDescription.length;
    }
  });
  return events;
}

/* Concat the subEvents into a single array of events. */
export function concatSubEvents(subEventsCollection) {
  let events = [];
  subEventsCollection.forEach((subEvents) => {
    events = [...events, ...subEvents.events];
  });
  return events;
}

/* Removes all HTML tags from a string. Useful in removing HTML tags from description fields. */
export function removeHtmlTags(string) {
  return string
    .replace(/(<([^>]+)>)+/gi, " ") // replaces html tags and new lines with " "
    .replace(/\r?\n{2,}/g, "\n") // replace 2 or more continuous line breaks with 1 line break
    .replace(/([.,\/#!$%\^&\*;:{}=\-_`~()])\1{2,}/g, "$1") // replace 3 or more continuous special chars with 1 of that char
    .trim();
}

/* Takes a string, and shortens it to the number of chars defined by maxLength. */
export function shortenString(string, maxLength) {
  if (string.length <= maxLength) {
    return string; // return the original string if it's already shorter than maxLength
  } else {
    return string.slice(0, maxLength) + "..."; // return a truncated string with ellipsis
  }
}

/* Takes an email address, and returns the part after the "@" symbol. */
export function getDomainFromEmail(email) {
  const atIndex = email.indexOf("@");
  if (atIndex === -1) {
    throw new Error("Invalid email address");
  }
  return email.slice(atIndex + 1);
}

/* Determines whether the user is authorized to use the service. */
export async function isUserAuthorizedToUseService(user, idTokenResult) {
  if (idTokenResult?.claims?.stripeRole === PRICE_TIERS.PROFESSIONAL) {
    return hasActiveSubscription(user);
  }
  return false;
}

/* Determines whether the user has an active subscription. */
export async function hasActiveSubscription(user) {
  const q = query(collection(db, "users", user.uid, "subscriptions"), where("status", "in", ["trialing", "active"]));
  const subsCountSnapshot = await getCountFromServer(q);

  // We expect only one active or trialing subscription to exist.
  if (subsCountSnapshot.data().count > 0) {
    return true;
  } else {
    return false;
  }
}

/* Given the tier and the term chosen by the user, returns the Stripe price option. */
export function getStripePriceOption(priceTier, priceTerm) {
  let stripePriceOption = null;
  if (priceTier === PRICE_TIERS.PROFESSIONAL) {
    if (priceTerm === PRICE_TERMS.QUARTERLY) {
      stripePriceOption = STRIPE_PRICE_OPTIONS.PROFESSIONAL_QUARTERLY;
    } else if (priceTerm === PRICE_TERMS.YEARLY) {
      stripePriceOption = STRIPE_PRICE_OPTIONS.PROFESSIONAL_YEARLY;
    }
  } else if (priceTier === PRICE_TIERS.TEAM) {
    if (priceTerm === PRICE_TERMS.QUARTERLY) {
      stripePriceOption = STRIPE_PRICE_OPTIONS.TEAM_QUARTERLY;
    } else if (priceTerm === PRICE_TERMS.YEARLY) {
      stripePriceOption = STRIPE_PRICE_OPTIONS.TEAM_YEARLY;
    }
  }
  return stripePriceOption;
}

/* Return the startDate and endDate for each of the deafult report range
   options. e.g. "This Week" will return this week's Sunday (past or today) 
   and Saturday (future or today). */
export function getDateRangeForReportRangeOption(reportRangeOption) {
  let startDate, endDate;

  const today = new Date();
  const dayOfWeek = today.getDay();
  const startOfWeek = new Date(today);
  startOfWeek.setDate(today.getDate() - dayOfWeek);
  startOfWeek.setHours(0, 0, 0, 0);

  const endOfWeek = new Date(today);
  endOfWeek.setDate(today.getDate() - dayOfWeek + 6);
  endOfWeek.setHours(23, 59, 59, 999);

  switch (reportRangeOption) {
    case REPORT_RANGE_OPTIONS.LAST_WEEK:
      startDate = new Date(startOfWeek);
      startDate.setDate(startOfWeek.getDate() - 7);
      endDate = new Date(endOfWeek);
      endDate.setDate(endOfWeek.getDate() - 7);
      break;
    case REPORT_RANGE_OPTIONS.THIS_WEEK:
      startDate = new Date(startOfWeek);
      endDate = new Date(endOfWeek);
      break;
    case REPORT_RANGE_OPTIONS.NEXT_WEEK:
      startDate = new Date(startOfWeek);
      startDate.setDate(startOfWeek.getDate() + 7);
      endDate = new Date(endOfWeek);
      endDate.setDate(endOfWeek.getDate() + 7);
      break;
    case REPORT_RANGE_OPTIONS.LAST_MONTH:
      startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999);
      break;
    case REPORT_RANGE_OPTIONS.THIS_MONTH:
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);
      break;
    case REPORT_RANGE_OPTIONS.NEXT_MONTH:
      startDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 2, 0, 23, 59, 59, 999);
      break;
    case REPORT_RANGE_OPTIONS.LAST_QUARTER:
      startDate = new Date(today.getFullYear(), Math.floor((today.getMonth() - 3) / 3) * 3, 1);
      endDate = new Date(today.getFullYear(), Math.floor((today.getMonth() - 3) / 3) * 3 + 3, 0, 23, 59, 59, 999);
      break;
    case REPORT_RANGE_OPTIONS.THIS_QUARTER:
      startDate = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3, 1);
      endDate = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3 + 3, 0, 23, 59, 59, 999);
      break;
    case REPORT_RANGE_OPTIONS.NEXT_QUARTER:
      startDate = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3 + 3, 1);
      endDate = new Date(today.getFullYear(), Math.floor(today.getMonth() / 3) * 3 + 6, 0, 23, 59, 59, 999);
      break;
    default:
      throw new Error(`Invalid report range option: ${reportRangeOption}`);
  }
  return { startDate: startDate, endDate: endDate };
}

/* Return startDate and endDate for an event block Id.
   e.g. 202309 will return { startDate: Sep 1, 2023, endDate: Sep 30, 2023 } */
export function getDateRangeForEventBlockId(eventBlockId) {
  const year = parseInt(eventBlockId.substring(0, 4));
  const month = parseInt(eventBlockId.substring(4, 6));
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(year, month, 0, 23, 59, 59, 999);
  return { startDate, endDate };
}

/* Return startDate and endDate for a group of event block Ids.
   It returns the startDate of the chronologically first event block,
   and the endDate of the chronologically last event block. e.g.
   For [202301, 202303], it will return:
   { startDate: Jan 1, 2023, endDate: Mar 31, 2023 }*/
export function getDateRangeForEventBlockIds(eventBlocks) {
  let startDate = null;
  let endDate = null;

  eventBlocks.forEach((eventBlock) => {
    const sDate = getDateRangeForEventBlockId(eventBlock).startDate;
    const eDate = getDateRangeForEventBlockId(eventBlock).endDate;

    if (!startDate || !endDate) {
      startDate = sDate;
      endDate = eDate;
    } else {
      if (sDate.getTime() < startDate.getTime()) {
        startDate = sDate;
      }
      if (eDate.getTime() > endDate.getTime()) {
        endDate = eDate;
      }
    }
  });

  return { startDate: startDate, endDate: endDate };
}

/* Return event block Id for a date.
   e.g. Mar 12, 2023 will return 202303 */
export function getEventBlockIdForDate(date) {
  const d = new Date(date);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  return `${year}${month.toString().padStart(2, "0")}`;
}

/* Return an array of event block Ids for a date range.
   e.g. startDate: Mar 31, 2023, endDate: May 2, 2023
   return [202303, 202304, 202305] */
export function getEventBlockIdsForDateRange(dateRange) {
  const startDate = dateRange.startDate;
  const endDate = dateRange.endDate;

  const startYear = startDate.getFullYear();
  const endYear = endDate.getFullYear();

  const startMonth = startDate.getMonth() + 1;
  const endMonth = endDate.getMonth() + 1;

  let eventBlocks = [];

  if (startYear === endYear && startMonth === endMonth) {
    const eventBlock = `${startYear}${startMonth.toString().padStart(2, "0")}`;
    eventBlocks.push(eventBlock);
  } else {
    for (let year = startYear; year <= endYear; year++) {
      const startMonthOfYear = year === startYear ? startMonth : 1;
      const endMonthOfYear = year === endYear ? endMonth : 12;

      for (let month = startMonthOfYear; month <= endMonthOfYear; month++) {
        const eventBlock = `${year}${month.toString().padStart(2, "0")}`;
        eventBlocks.push(eventBlock);
      }
    }
  }
  return eventBlocks;
}

/* Fetch events from Firestore for the given eventBlockIds */
export async function fetchEvents(user, calendarId, eventBlockIds) {
  const allEvents = [];

  const promises = eventBlockIds.map(async (eventBlockId) => {
    const eventsCollectionRef = collection(
      db,
      "users",
      user.uid,
      "calendars",
      calendarId,
      "event_blocks",
      eventBlockId,
      "events"
    );
    const q = query(eventsCollectionRef, orderBy("order"));

    try {
      const querySnap = await getDocs(q);
      for (let i = 0; i < querySnap.size; i++) {
        const eventsDoc = querySnap.docs[i];
        const eventsDocEvents = eventsDoc.data().events;
        eventsDocEvents.forEach((event) => {
          allEvents.push(event);
        });
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error("💥 Error getting events documents: ", error);
      }
    }
  });

  await Promise.all(promises);
  return allEvents;
}

/* Given eventBlockIds, fetch each eventBlock doc to check the "updated" value against the
   settings.TIME_TO_LIVE value. If any of the event block's updated value has been more than 
   TIME_TO_LIVE milliseconds ago, then TTL has expried and we return true. */
export async function isTTLexpired(user, calendarId, eventBlockIds) {
  let isTTLexpired = false;

  const promises = eventBlockIds.map(async (eventBlockId) => {
    try {
      const eventBlockDocRef = doc(db, "users", user.uid, "calendars", calendarId, "event_blocks", eventBlockId);
      const eventBlockDocSnap = await getDoc(eventBlockDocRef);

      if (!eventBlockDocSnap.exists()) {
        // if no event block exists, then return true so that we can fetch the events from the API.
        isTTLexpired = true;
      } else {
        const updated = convertFirebaseDate(eventBlockDocSnap.data().updated);

        if (updated.getTime() + TIME_TO_LIVE <= Date.now()) {
          isTTLexpired = true;
          if (process.env.NODE_ENV === "development") {
            console.log("🤯 TTL expired for " + eventBlockId + ": ", isTTLexpired);
          }
        }
      }
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error("💥 Error getting event block documents: ", error);
      }
    }
  });

  await Promise.all(promises);
  return isTTLexpired;
}

/* Given an array of events, group them into separate arrays of
   event blocks. Returns an object of event blocks containing events.
   e.g. { 202309: [{event}, {event}, ...],
          202310: [{event}, {event}, ...],
          ...} */
export function getEventBlocksForEvents(events) {
  const eventBlocks = {};
  events.forEach((event) => {
    // startDate decides what event block the event belongs to.
    let eventBlockId;
    if (event.start && event.start.dateTime) {
      eventBlockId = getEventBlockIdForDate(event.start.dateTime);
    } else {
      // Handle all-day events.
      eventBlockId = getEventBlockIdForDate(event.start.date);
    }

    if (!eventBlocks.hasOwnProperty(eventBlockId)) {
      eventBlocks[eventBlockId] = [];
    }

    eventBlocks[eventBlockId].push(event);
  });

  return eventBlocks;
}

/* Given an array of events, and a date range, return only those events
   that are within the specified date ragne. */
export function filterEventsForDateRange(events, startDate, endDate) {
  const filteredEvents = [];
  events.forEach((event) => {
    const eventStartDate = new Date(event.start.dateTime);
    const eventEndDate = new Date(event.end.dateTime);

    if (eventStartDate >= startDate && eventEndDate <= endDate) {
      filteredEvents.push(event);
    }
  });
  return filteredEvents;
}

/* Given an array of organizers, return the super organizers, as defined by the rules. */
export function getSuperOrganizers(reportData, eventCollections) {
  const organizers = Object.values(eventCollections.organizers.data);
  const superOrganizers = [];
  organizers.forEach((organizer) => {
    if (
      organizer.count / (eventCollections._numberOfWorkDays / eventCollections._workDaysOfWeek.length) >
      reportData.rules.super_organizers_meeting_number_threshold
    ) {
      superOrganizers.push(organizer);
    }
  });
  return superOrganizers;
}

/* Given an array of attendees, return the best buddies, as defined by the rules. */
export function getBestBuddies(reportData, eventCollections) {
  const attendees = Object.values(eventCollections.attendees.data);
  const bestBuddies = [];
  attendees.forEach((attendee) => {
    if (
      attendee.count / (eventCollections._numberOfWorkDays / eventCollections._workDaysOfWeek.length) >
      reportData.rules.best_buddies_meeting_number_threshold
    ) {
      bestBuddies.push(attendee);
    }
  });
  return bestBuddies;
}
