import React, { useContext, useRef, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthContext } from "./functions/authContext";
import { connectFirestoreEmulator } from "firebase/firestore";
import { connectFunctionsEmulator } from "firebase/functions";
import { connectAuthEmulator } from "firebase/auth";
import { db, functions } from "./config/firebaseConfig";
import Loader from "./components/Loader";

const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/About"));
const SignIn = lazy(() => import("./pages/SignIn"));
const Report = lazy(() => import("./pages/Report"));
const Profile = lazy(() => import("./pages/Profile"));
const Subscribe = lazy(() => import("./pages/Subscribe"));
const SignOut = lazy(() => import("./pages/SignOut"));
const Error401 = lazy(() => import("./pages/Error401"));
const Privacy = lazy(() => import("./pages/Privacy"));
const Terms = lazy(() => import("./pages/Terms"));
const Nav = lazy(() => import("./components/Nav"));
const Footer = lazy(() => import("./components/Footer"));
const Toast = lazy(() => import("./components/Toast"));

export default function App() {
  const { user } = useContext(AuthContext);
  const pricingRef = useRef(null);

  // For local testing
  if (process.env.NODE_ENV === "development") {
    const EMULATORS_STARTED = "EMULATORS_STARTED";
    function startEmulators() {
      if (!global[EMULATORS_STARTED]) {
        global[EMULATORS_STARTED] = true;
        // connectFirestoreEmulator(db, "localhost", 8080);
        // connectFunctionsEmulator(functions, "localhost", 5001);
        // connectAuthEmulator(auth, "http://localhost:9099");
      }
    }
    startEmulators();
  }

  return (
    <Router>
      <div className="overflow-x-hidden bg-gray-50 grid min-h-screen">
        <Suspense fallback={<Loader bounce={true} />}>
          {user !== null ? (
            <React.Fragment>
              <Nav pricingRef={pricingRef} />
              <Routes>
                <Route path="/" element={<Home pricingRef={pricingRef} />} />
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signout" element={<SignOut />} />
                <Route path="/report" element={<Report />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/subscribe" element={<Subscribe />} />
                <Route path="/about" element={<About />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="*" element={<Error401 />} />
              </Routes>
              <Footer />
            </React.Fragment>
          ) : (
            <Loader bounce={true} loadingText={"Loading app..."} />
          )}
        </Suspense>
      </div>
    </Router>
  );
}
