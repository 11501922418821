import React, { useEffect, useState } from "react";
import { auth, db, functions } from "../config/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { AUTH_PROVIDERS, SESSION_STORAGE_NAMES, TOAST_TYPES, TOAST_MESSAGES } from "../config/options";
import { TOKEN_EXPIRATION, TOAST_ERROR_DISPLAY_DURATION } from "../config/settings.js";
import { getProvider } from "../functions/util";
import {
  GoogleAuthProvider,
  OAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  getIdToken,
  getIdTokenResult,
} from "firebase/auth";
import Toast from "../components/Toast";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [rules, setRules] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [signOutUser, setSignOutUser] = useState(false);
  const [idTokenResult, setIdTokenResult] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [toastType, setToastType] = useState(TOAST_TYPES.SUCCESS);
  const [showMessage, setShowMessage] = useState(TOAST_MESSAGES.FB_ERROR);

  useEffect(() => {
    onAuthStateChanged(
      auth,
      async (user) => {
        /* onAuthStateChanged gets executed when user signs in or out of Firebase. It also gets called
           when page loads/refreshes.
           
           Firebase sessions are handled by Firebase. There is no need to separately manage accessTokens for
           Firebase. Therefore, users will remain logged into Firebase even when the page refreshes, or browser
           tab is closed and reopened.
          
           However, Firebase does NOT manage other providers' OAuth tokens for us. By design Firebase is AuthN
           only, which allows identity management, and does not support AuthZ, which allows resource management.
           Therefore, in order to fetch resources via the Google or Microsoft APIs, access tokens must be 
           managed separately by the app.

           Our token management approach is as follows (in the presented order):
           Option #1. Session Storage: 
              Google/Microsoft accessTokens generally expire in 60 minutes. So, we store the fetched access
              tokens, along with an expiration date of 50 minutes, in session storage. This is the first
              place we check to see if we still have a valid access token for the user.
        
           Option #2. Refresh Token:
              Refresh tokens are issued by Google and Microsoft when the user first signs into the app.
              We store the refresh token in the user doc in Firestore so we can use it to fetch new access 
              tokens as needed. If session storage fails, we use the refresh token to fetch a new access token.
            
           Option #3. Log In: 
              If the above steps fail, then we simply direct user to log in again. This will allow us to fetch
              a new access token. */

        if (user) {
          /* Setting the user state triggers the useEffect that sets the access token. */
          setUser(user);
          /* Fetch idTokenResult, which contains custom claims. */
          setIdTokenResult(await getIdTokenResult(user));

          if (process.env.NODE_ENV === "development") {
            console.log("ℹ️ %cidTokenResult set", "color: green");
            console.log("😀 user: ", user);
            console.log("👉 claims: ", idTokenResult?.claims);
          }
        } else {
          setUser(undefined);
        }
      },
      (error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
      }
    );

    getRedirectResult(auth)
      .then(async (result) => {
        if (process.env.NODE_ENV === "development") {
          console.log("🗝️ auth: ", auth);
        }

        /* getRedirectResult gets called each time the page loads or refreshes.
           If result is populated, it means the user has just returned here from the Google or Microsoft
           sign up flow. In all other cases of page load/refresh, result will be null. That means the 
           following section will only get execuated when the user it signing in via Google or Microsoft. */
        if (!result) {
          return;
        }

        /**************************/
        /* Option #3: Handle login
        /**************************/
        let token;
        if (getProvider(auth.currentUser) === AUTH_PROVIDERS.GOOGLE) {
          token = GoogleAuthProvider.credentialFromResult(result).accessToken;
          setAccessToken(token);
        }
        if (getProvider(auth.currentUser) === AUTH_PROVIDERS.MICROSOFT) {
          token = OAuthProvider.credentialFromResult(result).accessToken;
          setAccessToken(token);
        }
        saveAccessTokenInSession(token);
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }
        switch (error.code) {
          case "auth/account-exists-with-different-credential":
            setShowMessage(TOAST_MESSAGES.ACCOUNT_EXISTS_WITH_DIFF_CRED);
            setToastType(TOAST_TYPES.ERROR);
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, TOAST_ERROR_DISPLAY_DURATION);
            break;
          case "auth/web-storage-unsupported":
            setShowMessage(TOAST_MESSAGES.WEB_STORAGE_UNSUPPORTED);
            setToastType(TOAST_TYPES.ERROR);
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, TOAST_ERROR_DISPLAY_DURATION);
            break;
          default:
            setShowMessage(TOAST_MESSAGES.FB_ERROR);
            setToastType(TOAST_TYPES.ERROR);
            setShowToast(true);
            setTimeout(() => {
              setShowToast(false);
            }, TOAST_ERROR_DISPLAY_DURATION);
        }
      });
  }, []);

  /*************************************************************************/
  /* Set accessToken
  /*************************************************************************/
  useEffect(() => {
    if (!user) {
      return;
    }

    fetchAccessToken();
  }, [user]);

  const fetchAccessToken = async (skipSessionStorage = false) => {
    let accessToken;
    /******************************/
    /* Option #1: sessionStorage
      /******************************/
    if (!skipSessionStorage) {
      accessToken = getAccessTokenFromSessionStorage();
    }

    if (!accessToken) {
      /**********************************************/
      /* Option #2: get a new one using refreshToken
        /**********************************************/
      accessToken = await getAccessTokenFromProvider(user);
      saveAccessTokenInSession(accessToken);
    }

    if (!accessToken) {
      /* If access token is not fetched for whatever reason, we go to option 3: log in again. This should fix
         most issues. However, to not take any chances, we also assume the worst case scenario here -- that 
         the refresh token on file is invalid. We will attempt to get a new refresh token when the user 
         logs in again. 
         
         This happens automatically for Microsoft anyway, because they return a new refresh token with each user 
         sign in. So, for Microsoft we'll simply log out the user, and ask the user to log in again, and then 
         fetch/store the new refresh token.
         
         For Google, we need to set "prompt: consent" to get a new refresh token. Otherwise, no new refresh token
         will be issued when the user logs in. We obviously don't want to ask user to consent to giving us permission
         to access their resouces every single time they log in. Therefore, in order to know when to ask for consent
         again, we set askForConsentGoogle flag here, which will trigger a process that signs out the user, and then 
         takes the user to the sign in page that will prompt the user to give consent again. This will fetch a new 
         refresh token for the user. 
         
         Since AuthContext lives above the Router, we set signOutUser to true and this gets picked up by the Nav
         component to redirect user to signout. signOutUser is then set to default (false) by the signout component.*/
      setSignOutUser(true);
      return;
    }

    /* Setting the accessToken state triggers the useEffect that sets the user rules. */
    setAccessToken(accessToken);
  };

  /*************************************************************************/
  /* Set user rules
  /*************************************************************************/
  useEffect(() => {
    if (!accessToken) {
      return;
    }

    const setRulesState = async () => {
      /* Setting the rules will trigger the report process in the Report component. */
      setRules(await getRulesForUser(user));
    };
    setRulesState();
  }, [accessToken]);

  const handleSignIn = (authProvider, fetchGoogleRefreshToken = false) => {
    /* Firebase session persistence set so that browser refresh won't end the session. */
    setPersistence(auth, browserSessionPersistence)
      .then(() => {
        let provider = null;

        if (authProvider === AUTH_PROVIDERS.GOOGLE) {
          provider = new GoogleAuthProvider();
          provider.addScope("https://www.googleapis.com/auth/calendar.readonly");
          if (fetchGoogleRefreshToken) {
            /* Setting prompt to "consent", along with access_type: "offline", will ask for
               user consent again when logging in, and in turn fetch a new refresh token.
               We don't need to do this for Microsoft because they return a new refresh token
               with each log in, even if prompt is set to "select_account".
               fetchGoogleRefreshToken will be set to true if a new access token is not returned
               when we request for a new one using the refresh token on file. If this happens,
               we assume the refresh token is invalid or missing. */
            provider.setCustomParameters({
              access_type: "offline",
              prompt: "consent",
            });
          } else {
            provider.setCustomParameters({
              access_type: "offline",
              prompt: "select_account",
            });
          }
        }

        if (authProvider === AUTH_PROVIDERS.MICROSOFT) {
          provider = new OAuthProvider("microsoft.com");
          provider.addScope("calendars.read");
          provider.addScope("mailboxsettings.read"); // We fetch user's timezone from this
          provider.addScope("offline_access");
          provider.setCustomParameters({
            prompt: "select_account",
          });
        }

        signInWithRedirect(auth, provider).catch((error) => {
          if (process.env.NODE_ENV === "development") {
            console.error(error);
          }
          setShowMessage(TOAST_MESSAGES.FB_ERROR);
          setToastType(TOAST_TYPES.ERROR);
          setShowToast(true);
          setTimeout(() => {
            setShowToast(false);
          }, TOAST_ERROR_DISPLAY_DURATION);
        });
      })
      .catch((error) => {
        if (process.env.NODE_ENV === "development") {
          console.error(error);
        }

        setShowMessage(TOAST_MESSAGES.FB_ERROR);
        setToastType(TOAST_TYPES.ERROR);
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, TOAST_ERROR_DISPLAY_DURATION);
      });
  };

  const refreshIdToken = async (user) => {
    /* Force-refresh the ID token (e.g., to get the updated custom claims).
       By passing true, you ensure that the ID token is refreshed from Firebase before it is returned, 
       even if it hasn't expired yet. This can be useful in situations where you've updated custom claims 
       for the user, and you need to get a new ID token containing the updated claims. */
    await getIdToken(user, true);
    setIdTokenResult(await getIdTokenResult(user));
  };

  const getAccessTokenFromSessionStorage = () => {
    const sessionAccessToken = sessionStorage.getItem(SESSION_STORAGE_NAMES.ACCESS_TOKEN);
    const sessionAccessTokenExpiration = parseInt(sessionStorage.getItem(SESSION_STORAGE_NAMES.EXPIRATION));
    if (process.env.NODE_ENV === "development") {
      console.log("🌭 %cfetching access token from session storage... ", "color: green", {
        token: sessionAccessToken,
        expiration: new Date(sessionAccessTokenExpiration),
      });
    }

    if (sessionAccessToken && sessionAccessTokenExpiration) {
      /* If access token exists, check if it's valid (i.e. hasn't expired). */
      if (new Date().getTime() < new Date(sessionAccessTokenExpiration).getTime()) {
        if (process.env.NODE_ENV === "development") {
          console.log("ℹ️ %ctoken in session still valid", "color: green");
        }
        return sessionAccessToken;
      } else {
        if (process.env.NODE_ENV === "development") {
          console.log("💢 %ctoken in session storage expired", "color: red");
        }
      }
    }
    return null;
  };

  const getAccessTokenFromProvider = async (user) => {
    if (process.env.NODE_ENV === "development") {
      console.log("🐕 fetching new accessToken using refreshToken... ");
    }
    let newAccessToken;
    try {
      const getAccessToken = httpsCallable(functions, "getAccessToken");
      newAccessToken = await getAccessToken({ uid: user.uid, providerId: getProvider(user) });
    } catch (error) {
      newAccessToken = null;
    }

    if (newAccessToken?.data) {
      if (process.env.NODE_ENV === "development") {
        console.log("ℹ️ %cnew accessToken fetched", "color: green");
      }
      return newAccessToken.data;
    }

    if (process.env.NODE_ENV === "development") {
      console.log("💢 %cnew accessToken not fetched", "color: red");
    }
    return null;
  };

  const saveAccessTokenInSession = async (accessToken) => {
    if (process.env.NODE_ENV === "development") {
      console.log("ℹ️ %csaving accessToken in session storage...", "color: green");
    }
    sessionStorage.setItem(SESSION_STORAGE_NAMES.ACCESS_TOKEN, accessToken);
    sessionStorage.setItem(SESSION_STORAGE_NAMES.EXPIRATION, new Date().getTime() + TOKEN_EXPIRATION);
  };

  /* Create user doc for new users. Return rules. */
  const getRulesForUser = async (user) => {
    const userDocRef = doc(db, "users", user.uid);
    const docSnapshot = await getDoc(userDocRef);
    /* If user exists, then fetch the user's rules.
       We have to check not just if the user doc exists, but that is has the field "email" set.
       It's possible that the user doc was already created by the blocking function that sets the 
       refresh_token. If "email" field does not exist, we still must run processNewUser() to add
       fields such as "email", and "rules" to the user doc. Without them, report can't run. */
    if (docSnapshot.exists() && docSnapshot.data().hasOwnProperty("email")) {
      return docSnapshot.data().rules;
    }

    /* For new users, first create the user doc, and the fetch the default rules. */
    try {
      const processNewUser = httpsCallable(functions, "processNewUser");
      const default_report_rules = await processNewUser({ user: { email: user.email, uid: user.uid } });

      if (process.env.NODE_ENV === "development") {
        console.log("ℹ️ %cuser doc added successfully", "color: green");
      }

      return default_report_rules.data;
    } catch (error) {
      if (process.env.NODE_ENV === "development") {
        console.error(error);
      }
    }
    return null;
  };

  return (
    <React.Fragment>
      <AuthContext.Provider
        value={{
          user,
          rules,
          idTokenResult,
          accessToken,
          signOutUser,
          setSignOutUser,
          fetchAccessToken,
          setAccessToken,
          handleSignIn,
          setRules,
          refreshIdToken,
        }}
      >
        {children}
      </AuthContext.Provider>
      {/* toast */}
      <Toast type={toastType} showToast={showToast} message={showMessage} />
    </React.Fragment>
  );
};
