import React from "react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { AuthProvider } from "./functions/authContext";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./assets/index.css";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://07d83363084c42bf880b7dcde1191e76@o4504761761267712.ingest.sentry.io/4504761763037184",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.2,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
