import { DIST_TEMPLATE_OPTIONS } from "../config/options.js";
import { INTEGER, STAT_DECIMAL_POINTS } from "../config/settings.js";
import { dpFormat } from "./util";

/* Given a float time value, return the hour.
   e.g. 3.5 => return 3
        0.5 => return 0 
        1 => return 1 */
export function getHourFromFloat(floatTimeValue) {
  return Math.trunc(floatTimeValue);
}

/* Given a float time value, return the minute.
   e.g. 3.5 => return 30
        0.25 => return 15
        1 => return 0 */
export function getMinuteFromFloat(floatTimeValue) {
  return parseFloat(floatTimeValue % 1) * 60;
}

/* Given a minute value, return the float value.
   e.g. 45 => return 0.75 */
export function getFloatFromMinute(minuteValue) {
  return minuteValue / 60;
}

/* Returns true if two dates are on the same day. */
export function isSameDay(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

/* Returns true if two dates are on the same hour, min, sec.
   They can be on different days. */
export function isSameTime(date1, date2) {
  return (
    date1.getHours() === date2.getHours() &&
    date1.getMinutes() === date2.getMinutes() &&
    date1.getSeconds() === date2.getSeconds()
  );
}

/* Given a date object, return a localized date string.
   For US, it looks like "2023-03-23". */
export function getDateStringLocalized() {
  const today = new Date();
  const formatter = new Intl.DateTimeFormat(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return formatter.format(today);
}

/* Given a date object, return a "yyyy-mm-dd" ISO string, regardless of what local the user is in.
   (Note: toISOString() does this technically but it automatically converts date to UTC. This method
   will respect the original date's time zone.) */
export function getLocalISOString(date) {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return year + "-" + month + "-" + day;
}

/* Given a date object, return a short date string. e.g.
   "12 Jan" 
   The "month" variable can be optionally set to "numeric". e.g.
   "1/12" */
export function getShortDateString(date, month = "short") {
  if (!date) {
    return "";
  }
  return date.toLocaleDateString("en-US", { day: "numeric", month: month });
}

/* Given a 24 hour string (e.g. "6" or "18"), return a string
   in this format: "06:00" or "18:00". */
export function get24HrStrFormat(time) {
  return (time < 10 ? "0" : "") + time + ":00";
}

/* Given a Date object, return a time string in the format:
   6:30 pm */
export function getTimeString(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
}

/* Given a duration float value, return a readable string. e.g.
   1.5 => 1 hr 30 mins */
export function getDurationString(durationValue) {
  const hour = getHourFromFloat(durationValue);
  const minute = getMinuteFromFloat(durationValue);
  let hourString = "";
  let minuteString = "";

  if (hour === 1) {
    hourString = " hr";
  } else {
    hourString = " hrs";
  }

  if (minute === 1) {
    minuteString = " min";
  } else {
    minuteString = " mins";
  }

  if (hour === 0) {
    return minute + minuteString;
  }
  if (minute === 0) {
    return hour + hourString;
  }
  return dpFormat(hour, INTEGER) + hourString + " " + dpFormat(minute, INTEGER) + minuteString;
}

/* Returns a number of days between two dates. 
   - Inclusive of start and end dates.
   - All days are counted (i.e. Non-work days are counted). */
export function daysBetween(date1, date2) {
  // Convert both dates to milliseconds
  var date1Ms = date1.getTime();
  var date2Ms = date2.getTime();
  // Calculate the difference in milliseconds
  var differenceMs = Math.abs(date1Ms - date2Ms);
  // Convert back to days and return
  return Math.round(differenceMs / 86400000);
}

/* Returns a number of hours between two dates. */
export function hoursBetween(date1, date2) {
  // Convert both dates to milliseconds
  var date1Ms = date1.getTime();
  var date2Ms = date2.getTime();
  // Calculate the difference in milliseconds
  var differenceMs = Math.abs(date1Ms - date2Ms);
  // Convert back to hours and return
  return differenceMs / 3600000;
}

/* Adds a number of days to a date. */
export function addDays(date, days) {
  var result = new Date(date);
  result.setTime(result.getTime() + days * 86400000);
  return result;
}

/* Given a number of hours, return milliseconds. */
export function inMilliseconds(hours) {
  return hours * 60 * 60 * 1000;
}

/* Given a number of days from today, get the start and end dates.
   startDate is set to 00:00am. endDate is set to 11:59pm. */
export function getDateRangeForNumberOfDays(days) {
  const today = new Date();
  const startDate = new Date(new Date().setDate(today.getDate() - days));
  startDate.setHours(0, 0, 0, 0);

  const endDate = new Date(new Date().setDate(today.getDate() - 1));
  endDate.setHours(23, 59, 59, 999);
  return { startDate: startDate, endDate: endDate };
}

/* Takes a list of attendees, returns an object cotaining separated lists of attendees and resources. */
export function separateResourcesFromAttendeesList(attendeesRef) {
  const realAttendees = [];
  const resources = [];
  attendeesRef.forEach((attendee) => {
    if (attendee.hasOwnProperty("resource") && attendee.resource === true) {
      resources.push(attendee);
      return;
    }
    realAttendees.push(attendee);
  });
  return { attendees: realAttendees, resources: resources };
}

/* Returns the title and date range for the most recent quarters. */
export function getMostRecentQuartersWithTitles(numQuarters) {
  const today = new Date();
  const quarterStartMonths = [0, 3, 6, 9]; // January, April, July, October

  const quarters = [];
  for (let i = 1; i <= numQuarters; i++) {
    // find the start month of the this quarter
    let thisMonth = today.getMonth() - i * 3;
    let thisYear = today.getFullYear();
    if (thisMonth < 0) {
      thisYear -= 1;
      thisMonth += 12;
    }
    const quarterStartMonth = quarterStartMonths[Math.floor(thisMonth / 3)];
    // create a new date object for the start date of the this quarter
    const quarterStartDate = new Date(thisYear, quarterStartMonth, 1);
    quarterStartDate.setHours(0, 0, 0, 0);
    let quarterEndMonth = quarterStartMonth + 2;
    let quarterEndDay = 31;
    if (quarterEndMonth === 5 || quarterEndMonth === 8) {
      // June and September end on the 30th.
      quarterEndDay = 30;
    }
    // create a new date object for the end date of the current quarter
    const quarterEndDate = new Date(thisYear, quarterEndMonth, quarterEndDay);
    quarterEndDate.setHours(23, 59, 59, 999);
    // calculate the title of the current quarter (e.g. "Q4 2022")
    const quarterTitle = `Q${Math.ceil((quarterStartDate.getMonth() + 1) / 3)} ${quarterStartDate.getFullYear()}`;
    // add the current quarter's title and date range to the array of quarters
    quarters.push({ title: quarterTitle, startDate: quarterStartDate, endDate: quarterEndDate });
  }
  return quarters;
}

/* Returns the title and date range for the most recent months. */
export function getMostRecentMonthsWithTitles(numMonths) {
  const today = new Date();
  const months = [];
  for (let i = 0; i < numMonths; i++) {
    let thisMonth = today.getMonth() - i;
    let thisYear = today.getFullYear();
    if (thisMonth < 0) {
      thisYear -= 1;
      thisMonth += 12;
    }
    // create a new date object for the start date of this month
    const monthStartDate = new Date(thisYear, thisMonth, 1);
    monthStartDate.setHours(0, 0, 0, 0);
    // create a new date object for the end date of this month
    const monthEndDate = new Date(thisYear, thisMonth + 1, 0);
    monthEndDate.setHours(23, 59, 59, 999);
    // calculate the title of this month (e.g. "April 2023")
    const monthTitle = `${monthStartDate.toLocaleString("default", {
      month: "short",
    })} ${monthStartDate.getFullYear()}`;
    // add the current month's title and date range to the array of months
    months.push({ title: monthTitle, startDate: monthStartDate, endDate: monthEndDate });

    console.log("month: ", { title: monthTitle, startDate: monthStartDate, endDate: monthEndDate });
  }
  return months;
}

/* Given the report range, return the most appropriate title.
   e.g. Jan 1 - Mar 31, 2023 => "Q1 2023"
        Feb 10 - Apr 25, 2023 => "Feb - Apr 2023"
        Apr 2 - Apr 10, 2023 => "Apr 2023" */
export function getReportRangeTitle(sDate, eDate) {
  const startDate = new Date(sDate);
  const endDate = new Date(eDate);
  const quarterStartMonths = [0, 3, 6, 9]; // January, April, July, October
  const startMonth = startDate.getMonth();
  const endMonth = endDate.getMonth();
  const startDateDate = startDate.getDate();
  const endDateDate = endDate.getDate();

  if (quarterStartMonths.includes(startMonth)) {
    if (startDateDate === 1) {
      switch (startMonth) {
        case 0:
          if (!endMonth === 2) break;
          if (!endDateDate === 31) break;
          return "Q1 " + startDate.getFullYear();
        case 3:
          if (!endMonth === 5) break;
          if (!endDateDate === 30) break;
          return "Q2 " + startDate.getFullYear();
        case 6:
          if (!endMonth === 8) break;
          if (!endDateDate === 30) break;
          return "Q3 " + startDate.getFullYear();
        case 9:
          if (!endMonth === 11) break;
          if (!endDateDate === 31) break;
          return "Q4 " + startDate.getFullYear();
        default:
          break;
      }
    }
  }
  if (startDate.getMonth() === endDate.getMonth()) {
    return endDate.toLocaleString("default", { month: "short" }) + " " + endDate.getFullYear();
  } else {
    return (
      startDate.toLocaleString("default", { month: "short" }) +
      " - " +
      endDate.toLocaleString("default", { month: "short" }) +
      " " +
      endDate.getFullYear()
    );
  }
}

/* Return the total number of working hours using the number of days and
   rules.work_starts, rules.work_ends. */
export function getTotalWorkingHours(workDatesRef, rulesRef) {
  const numberOfWorkDays = workDatesRef.length;
  const workingHoursPerDay = rulesRef.work_ends - rulesRef.work_starts;
  return numberOfWorkDays * workingHoursPerDay;
}

/* Return an array of work Date objects for the given date range. */
export function getWorkDates(startDatetimeRef, endDatetimeRef, rulesRef) {
  const workDates = [];
  const numberOfDays = daysBetween(startDatetimeRef, endDatetimeRef);

  let datePointer = new Date(startDatetimeRef.getTime());
  for (let i = 0; i <= numberOfDays; i++) {
    if (rulesRef.work_days.includes(datePointer.getDay())) {
      workDates.push(new Date(datePointer.getTime()));
    }
    datePointer.setDate(datePointer.getDate() + 1);
  }
  return workDates;
}

/* Return an array of work date strings for the given Date objects array. 
   e.g. ["Sat Dec 24 2022", "Sun Dec 25 2022", ...] */
export function getWorkDatesString(workDatesRef) {
  const workDatesString = [];
  workDatesRef.forEach((date) => {
    workDatesString.push(date.toDateString());
  });
  return workDatesString;
}

/* Count each occurrence of work day within the given range. The returned
   Object's keys are the day of week (int), and the values are the 
   counts for each day. */
export function getWorkDayOfWeekCounts(workDatesRef) {
  const dayCounts = {
    /* For the default works_days array, it will look like
         1: x,
         2: x,
         3: x,
         4: x,
         5: x, */
  };
  workDatesRef.forEach((date) => {
    if (!dayCounts[date.getDay()]) {
      dayCounts[date.getDay()] = 0;
    }
    dayCounts[date.getDay()]++;
  });
  return dayCounts;
}

/* Given an array of day values (int), return an array of String day value. */
export function getDaysString(days) {
  const daysString = [];
  days.forEach((day) => {
    daysString.push(getDayString(day));
  });
  return daysString;
}

/* Given a day value (int), return a String day value. */
export function getDayString(day) {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return days[day];
}

/* Given a day value (string), return an index int value. */
export function getDayIndex(dayString) {
  const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  return days.indexOf(dayString);
}

/* numerators: array of durations total grouped by days of week. e.g. [9, 2, 5.5, 3, 8]
   denominators: array of work days of week counts e.g. [4, 4, 4, 4, 5] i.e. report range includes
   5 Fridays and 4 or all other work days. Dividing them gives average durations for days of week. */
export function getAvgsForDayOfWeekTotals(numerators, denominators) {
  return numerators.map(function (number, idx) {
    return parseFloat((number / denominators[idx]).toFixed(STAT_DECIMAL_POINTS));
  });
}

/* Takes an event from the events iteration, and also the ref to the collectionRef being processed
   (e.g. qualifiedEvents, oneOnOneMeetings), adds event to the events array, and increments the
   total recurring hours sum. Example:
    Collection: {
      recurringEvents: {
        0djpo3v9ic17v67o6u7bvq6hzk: {
          events: [event object, event object, ...],
          hours: 2.5
        }
      ...
      }
    ...
    } */
export function processRecurringEvents(eventRef, collectionRef, duration) {
  if (eventRef.recurringEventId) {
    collectionRef._recurringEventsTotalHours += duration;
    collectionRef._recurringEventsTotalCount++;
    if (!collectionRef.recurringEvents[eventRef.recurringEventId]) {
      collectionRef.recurringEvents[eventRef.recurringEventId] = { events: [], hours: 0 };
    }
    collectionRef.recurringEvents[eventRef.recurringEventId].events.push(eventRef);
    collectionRef.recurringEvents[eventRef.recurringEventId].hours += duration;
  }
}

/* Takes an event from the events iteration, and also refs to temporary grouping maps for that event,
   and increments sums for each day of the week. Temporary grouping map example structure:
   groupArr {
              { "Monday" => 2.5 }
              { "Tuesday" => 0.5 }
              ...
              ...
   } */
export function processGroupByDayOfWeek(eventRef, groupArrRef, groupRecArrRef, eventDay, duration) {
  groupArrRef[eventDay] += duration;
  if (eventRef.recurringEventId) groupRecArrRef[eventDay] += duration;
}

/* Takes an event from the events iteration, and also refs to temporary grouping maps for that event,
   increments sums for day in the report range. Temporary grouping map example structure:
   groupArr {
              { "Mon Jan 02 2023" => 2.5 }
              { "Tue Jan 03 2023" => 0.5 }
              ...
              ...
   } */
export function processGroupByDay(
  eventRef,
  groupArrRef,
  groupRecArrRef,
  groupOneoffArrRef,
  eventStartDatetimeRef,
  duration
) {
  const key = eventStartDatetimeRef.toDateString();
  const valueArr = groupArrRef.get(key) + duration;
  groupArrRef.set(key, valueArr);
  if (eventRef.recurringEventId) {
    const valueRecArr = groupRecArrRef.get(key) + duration;
    groupRecArrRef.set(key, valueRecArr);
  } else {
    const valueOneoffArr = groupOneoffArrRef.get(key) + duration;
    groupOneoffArrRef.set(key, valueOneoffArr);
  }
}

export function processHeatmap(heatmapRef, eventStartDatetimeRef, duration, eventDay) {
  const eventStartHour = eventStartDatetimeRef.getHours();
  const eventStartMinute = getFloatFromMinute(eventStartDatetimeRef.getMinutes());
  const eventDurationHours = Math.trunc(duration); // If event = 2.5, then this = 2
  const eventDurationRemainder = duration % 1; // If event = 2.5, then this = 0.5

  for (let i = 0; i <= eventDurationHours; i++) {
    if (i !== eventDurationHours) {
      if (heatmapRef.get(eventStartHour + i)) {
        if (eventStartMinute === 0) {
          heatmapRef.get(eventStartHour + i)[getDayString(eventDay)]++;
        } else {
          heatmapRef.get(eventStartHour + i)[getDayString(eventDay)] += 1 - eventStartMinute;
        }
      }
    }
    if (i === eventDurationHours) {
      if (heatmapRef.get(eventStartHour + i) && eventDurationRemainder !== 0) {
        heatmapRef.get(eventStartHour + i)[getDayString(eventDay)] += eventDurationRemainder;
      }
    }
  }
}

/* Gets the cateogory (key value) for the given distribution and value.
   See collectionRefTemplates.getEventDistributionTemplate for details on the structure. */
export function getDistCategory(distributionOption, value) {
  let key = null;

  switch (distributionOption) {
    case DIST_TEMPLATE_OPTIONS.DURATION:
    case DIST_TEMPLATE_OPTIONS.FOCUS_TIME:
    case DIST_TEMPLATE_OPTIONS.EXCESSIVE_BLOCKS:
      const minutes = getHourFromFloat(value) * 60 + getMinuteFromFloat(value);
      const category = Math.round(minutes / 30);
      if (category > 10) key = 10;
      else key = category === 0 ? 0 : category - 1;
      break;

    case DIST_TEMPLATE_OPTIONS.ATTENDEES:
      if (value >= 10) key = 10;
      else key = value;
      break;
  }
  return key;
}

/* Takes a ref to organizers and adds more insights such as the #1 meeting organizer. */
export function finalizeOrganizers(organizersRef, qualifedEventsRef) {
  /* Firstly, iterate through organizers to find additional insights. Organizers object example:
      organizers: {
        data: 
          {john@gmail.com: {email: 'john@gmail.com', displayName: "John Smith", count: 1, hours: 0.5, recurringHours: 0}}
          ...,
        eventsByOrganizers: ...,
        insights: {
          numberOneOrganizerByCount: {email: 'john@gmail.com', displayName: "John Smith", count: 119, hours: 94.75, recurringHours: 52.5}
          numberOneOrganizerByHours: {email: 'john@gmail.com', displayName: "John Smith", count: 119, hours: 94.75, recurringHours: 52.5}
        },
        organizedByOthers: {...},
        organizedByUser: {...}, 
      } */
  const organizersArray = Object.values(organizersRef.data);
  let numberOneOrganizerByCount = null;
  let numberOneOrganizerByHours = null;
  organizersArray.forEach((organizer) => {
    if (!numberOneOrganizerByCount) {
      numberOneOrganizerByCount = organizer;
    } else {
      if (organizer.count > numberOneOrganizerByCount.count) {
        numberOneOrganizerByCount = organizer;
      }
    }

    if (!numberOneOrganizerByHours) {
      numberOneOrganizerByHours = organizer;
    } else {
      if (organizer.count > numberOneOrganizerByHours.count) {
        numberOneOrganizerByHours = organizer;
      }
    }
  });
  organizersRef.insights = {
    numberOneOrganizerByCount: numberOneOrganizerByCount,
    numberOneOrganizerByHours: numberOneOrganizerByHours,
  };

  /* Secodly, iterate through qualified events to assign events to each organizer where they are
     the organizers of the event. Example:
      organizers: {
        ...
        eventsByOrganizers: {
          john@gmail.com: [event object, event object, ...]
          matt@hotmail.com: [event object, event object, ...]
          ...
        }
      } */
  const eventsByOrganizers = {};
  qualifedEventsRef.events.forEach((event) => {
    if (event.organizer) {
      if (!eventsByOrganizers[event.organizer.email]) {
        eventsByOrganizers[event.organizer.email] = [];
      }
      eventsByOrganizers[event.organizer.email].push(event);
    }
  });
  organizersRef.eventsByOrganizers = eventsByOrganizers;
}

/* Takes a ref to attendees and adds more insights such as the person you spend the most time with,
   and the person you meet most often with. It uses the same structure as finalizeOrganizers above. */
export function finalizeAttendees(attendeesRef, qualifedEvents, userEmail) {
  /* Firstly, iterate through attendees to find additional insights. */
  const attendeesArray = Object.values(attendeesRef.data);
  let mostTimeSpentWith = null;
  let meetMostWith = null;
  attendeesArray.forEach((attendee) => {
    if (!mostTimeSpentWith || !meetMostWith) {
      mostTimeSpentWith = attendee;
      meetMostWith = attendee;
    } else {
      if (attendee.hours > mostTimeSpentWith.hours) {
        mostTimeSpentWith = attendee;
      }
      if (attendee.count > meetMostWith.count) {
        meetMostWith = attendee;
      }
    }
  });
  attendeesRef.insights = {
    mostTimeSpentWith: mostTimeSpentWith,
    meetMostWith: meetMostWith,
  };

  /* Secodly, iterate through qualified events to assign events to each attendee where they are
     invited. This will allow us to know which events I'm attending with each attendee. */
  const eventsByAttendees = {};
  qualifedEvents.events.forEach((event) => {
    if (event.attendees) {
      event.attendees.forEach((attendee) => {
        if (attendee.email === userEmail) return; // Exclude the user
        if (attendee.hasOwnProperty("resource") && attendee.resource === true) return; // Exclude resources (e.g. meeting rooms)

        if (!eventsByAttendees[attendee.email]) {
          eventsByAttendees[attendee.email] = [];
        }
        eventsByAttendees[attendee.email].push(event);
      });
    }
  });
  attendeesRef.eventsByAttendees = eventsByAttendees;
}

/* Format for heatmap chart ingestion. Needs to be in this format:
    [{
      name: 10,
      data: [{x: "Monday", y: 5.5}, ...],
    },
    ...]

    The input map structure is outlined in collectionRefTemplates.getHeatmapTemplate.
  */
export function finalizeHeatmap(heatmapRef) {
  let formattedHeatmap = [];
  heatmapRef.forEach((value, key) => {
    const data = Object.entries(value).map(([x, y]) => ({ x, y }));
    formattedHeatmap = [{ name: key, data: data }, ...formattedHeatmap];
  });
  return formattedHeatmap;
}

export function finalizeCollection(
  collectionRef,
  workDayOfWeekCountsRef,
  eventDurationsByDayOfWeekRef,
  eventDurationsByDayOfWeekRecurringRef,
  eventDurationsByDayRef,
  eventDurationsByDayRecurringRef,
  eventDurationsByDayOneoffRef
) {
  collectionRef.eventDurationsByDayOfWeek._total = Object.values(eventDurationsByDayOfWeekRef);
  collectionRef.eventDurationsByDayOfWeek._average = getAvgsForDayOfWeekTotals(
    Object.values(eventDurationsByDayOfWeekRef),
    Object.values(workDayOfWeekCountsRef)
  );
  collectionRef.eventDurationsByDayOfWeek.recurringTotal = Object.values(eventDurationsByDayOfWeekRecurringRef);
  collectionRef.eventDurationsByDayOfWeek.recurringAverage = getAvgsForDayOfWeekTotals(
    Object.values(eventDurationsByDayOfWeekRecurringRef),
    Object.values(workDayOfWeekCountsRef)
  );
  collectionRef.eventDurationsByDayOfWeek.oneoffTotal = collectionRef.eventDurationsByDayOfWeek._total.map((val, i) =>
    parseFloat((val - collectionRef.eventDurationsByDayOfWeek.recurringTotal[i]).toFixed(STAT_DECIMAL_POINTS))
  );
  collectionRef.eventDurationsByDayOfWeek.oneoffAverage = collectionRef.eventDurationsByDayOfWeek._average.map(
    (val, i) =>
      parseFloat((val - collectionRef.eventDurationsByDayOfWeek.recurringAverage[i]).toFixed(STAT_DECIMAL_POINTS))
  );
  collectionRef.eventDurationsByDay._total = [...eventDurationsByDayRef.values()].map(function (value) {
    return Number(value.toFixed(STAT_DECIMAL_POINTS));
  });
  collectionRef.eventDurationsByDay.recurring = [...eventDurationsByDayRecurringRef.values()].map(function (value) {
    return Number(value.toFixed(STAT_DECIMAL_POINTS));
  });
  collectionRef.eventDurationsByDay.oneoff = [...eventDurationsByDayOneoffRef.values()].map(function (value) {
    return Number(value.toFixed(STAT_DECIMAL_POINTS));
  });
}
