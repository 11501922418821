import React from "react";
import classNames from "classnames";

export default function Toast({ type, showToast, message }) {
  return (
    <React.Fragment>
      <section
        className={classNames(
          "toast toast-top toast-right transition-opacity duration-300 animate-bounce w-6 h-6 ease-in-out",
          { "opacity-0 hidden": !showToast },
          { "opacity-100 block": showToast }
        )}
      >
        <div className={classNames("alert", type)}>
          <div>
            <span>{message}</span>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
