export const AUTH_PROVIDERS = {
  GOOGLE: "google.com", // This must be set to "google.com". This is what Firebase returns.
  MICROSOFT: "microsoft.com", //  This must be set to "microsoft.com". This is what Firebase returns.
};

export const SESSION_STORAGE_NAMES = {
  ACCESS_TOKEN: "minute_fyi_api_access_token",
  EXPIRATION: "minute_fyi_api_access_token_expiration",
};

/* Stored in custom claim "role". 
   Any changes should also be reflected in the cloud functions file beforeSignIn. */
export const ROLE_TYPES = {
  /* This is a minute-wide platform super admin role. */
  SUPERADMIN: "superadmin",

  /* These are for the users. */
  OWNER: "owner",
  ADMIN: "admin",
  USER: "user",
};

/* Price tiers. 
   These are also the names that Stripe will store in custom claim "stripeRole" */
export const PRICE_TIERS = {
  PROFESSIONAL: "professional",
  TEAM: "team",
};

export const PRICE_TERMS = {
  QUARTERLY: "quarterly",
  YEARLY: "yearly",
};

export const USER_STATUS = {
  ACTIVE: "active",
  DELETED: "deleted",
};

export const HOME_RECOMMENDED_USE_TAB_OPTIONS = {
  PROFESSIONALS: "professionals",
  MANAGERS: "managers",
};

export const STATUS_TYPES = {
  ON_TARGET: "on_target",
  CLOSE_TO_TARGET: "close_to_target",
  OFF_TARGET: "off_target",
};

export const DATE_RANGE_OPTIONS = {
  LAST_90: "Last 90 days",
  LAST_30: "Last 30 days",
  LAST_MONTH: "Last month",
  CUSTOM: "Custom range",
};

export const TABLE_TYPES = {
  EVENTS_TABLE: "eventsTable",
  ATTENDEES_TABLE: "attendeesTable",
  SUPER_ORGANIZERS_TABLE: "organizersTable",
  EXCESSIVE_BLOCKS_TABLE: "excessiveBlocksTable",
};

export const CHART_TYPES = {
  SUMMARY: "summary",
  DAYS_OF_WEEK: "daysOfWeek",
  DAILY_TREND: "dailyTrend",
  SCATTER: "scatter",
  HEATMAP: "heatmap",
  OVERVIEW: "overview",
  TREEMAP: "treemap",
  DISTRIBUTION: "distribution",
};

export const REPORT_MENU_OPTIONS = {
  OVERVIEW: "Overview",
  ALL_MEETINGS: "All Meetings",
  LONG_MEETINGS: "Long Meetings",
  BIG_MEETINGS: "Big Meetings",
  NO_AGENDA: "No Agenda",
  BEST_BUDDIES: "Best Buddies",
  SUPER_ORGANIZERS: "Super Organizers",
  FOCUS_TIME: "Focus Time",
  EXCESSIVE_BLOCKS: "Excessive Blocks",
  REPORT_SETTINGS: "Report Settings",
};

export const DIST_TEMPLATE_OPTIONS = {
  DURATION: "duration",
  ATTENDEES: "attendees",
  FOCUS_TIME: "focus_time",
  EXCESSIVE_BLOCKS: "excessive_blocks",
};

export const TOAST_TYPES = {
  SUCCESS: "alert-success",
  ERROR: "alert-error",
};

export const TOAST_MESSAGES = {
  SETTING_SAVED: "Report updated!",
  SETTING_SAVE_FAILED: "Report update failed. Please try logging-in again, or try later.",

  DELETE_REPORT: "Report deleted successfully.",
  DELETE_REPORT_FAILED: "Deleting report failed. Please try logging-in again, or try later.",

  DELETE_USER: "User deleted successfully.",
  DELETE_USER_FAILED: " Deleting user failed. Please try logging-in again, or try later.",

  FB_ERROR: "Firebase connection failed. Please try logging-in again, or try later.",
  GOOGLE_ERROR: "Error fetching data from Google. Please try logging-in again, or try later.",
  MICROSOFT_ERROR: "Error fetching data from Microsoft. Please try logging-in again, or try later.",

  ACCOUNT_EXISTS_WITH_DIFF_CRED:
    "This email account is already registered using another provider. Please choose the right identity provider.",
  WEB_STORAGE_UNSUPPORTED:
    "3rd party cookies and data may be disabled, or this browser is not supported. If you're using Incognito mode, please try the normal mode.",

  STRIPE_REDIRECTION_ERROR: "Stripe redirection failed. Please try again later.",
};

export const SETTINGS_TAB_OPTIONS = {
  QUALIFICATION: "qualification",
  CATEGORIZATION: "categorization",
  TARGETS: "targets",
};

export const PROFILE_TAB_OPTIONS = {
  PROFILE: "profile",
  ACCOUNT: "account",
  SUBSCRIPTION: "subscription",
};

export const CALENDAR_EVENT_SOURCES = {
  DATE: "date",
  EVENT: "event",
};
export const REPORT_LOADING_TEXT_TAKING_EXTRA_LONG = "Hang tight, almost there!";
export const REPORT_LOADING_TEXT_OPTIONS = [
  "Charging the flux capacitor...",
  "Entering the wormhole...",
  "Preparing for time inversion...",
  "Activating the Omega 13...",
  "Rotating the Time-Turner...",
  "Initiating time warp...",
  "Entering the Quantum Realm...",
  "Casting a spell with the Time Stone...",
  "Creating a time variance...",
  "Hot tub malfunction detected...",
  "Detecting a rift in the space-time continuum...",
  "Accelerating past the speed of light...",
];

export const TIP_TYPES = {
  QUESTION: "question",
  LIGHTBULB: "lightbulb",
};

export const TIP_OPTIONS = {
  OVERVIEW: "tip_option_overview",
  DAILY_TREND: "tip_option_daily_trend_chart",
  HEATMAP: "tip_option_heatmap",
  DAYS_OF_WEEK: "tip_option_days_of_week",
  TREEMAP: "tip_option_treemap",
  DISTRIBUTIONS: "tip_option_distributions",
  TABLE: "tip_option_table",
  OVERVIEW_ALL_MEETINGS: "tip_option_overview_all_meetings",
  OVERVIEW_MEETINGS: "tip_option_overview_meetings",
  OVERVIEW_PEOPLE: "tip_option_overview_people",
  OVERVIEW_EFFICIENCY: "tip_option_overview_efficiency",
};

export const DISQUALIFICATION_REASONS = {
  NON_CAL_EVENT: "This is not a calendar event. Non-calendar events are disqualified (e.g. tasks, reminders)",
  NON_DEFAULT_EVENT_TYPE:
    "This is not a default event type. Non-default events are disqualified (e.g. out of office, free time) ",
  ALL_DAY_EVENT: "This is an all day event. All day events are disqualified.",
  ACCEPTED_MEETINGS_ONLY: "This meeting has not been accepted. Accepted meetings only.",
  MIN_ATTENDEES_REQUIRED: "This meeting does not meet the minimum attendees requirement.",
  NON_WORK_DAY: "This meeting happens on a non-work day.",
  BEFORE_WORK_DAY_BEGINS: "This meeting starts before the work day begins.",
  AFTER_WORK_DAY_ENDS: "This meeting starts after the work day ends.",
  LONG_EVENT: "This meeting lasts longer than the maximum meeting length.",
};

export const TIME_GOAL_OPTIONS = {
  GOAL_10: { id: 10, string: "10% meetings / 90% free time" },
  GOAL_20: { id: 20, string: "20% meetings / 80% free time" },
  GOAL_30: { id: 30, string: "30% meetings / 70% free time" },
  GOAL_40: { id: 40, string: "40% meetings / 60% free time" },
  GOAL_50: { id: 50, string: "50% meetings / 50% free time" },
  GOAL_60: { id: 60, string: "60% meetings / 40% free time" },
  GOAL_70: { id: 70, string: "70% meetings / 30% free time" },
  GOAL_80: { id: 80, string: "80% meetings / 20% free time" },
  GOAL_90: { id: 90, string: "90% meetings / 10% free time" },
};

export const NO_AGENDA_MEETINGS_GOAL_OPTIONS = {
  GOAL_10: { id: 10, string: "10% no agenda meetings / 90% agenda meetings" },
  GOAL_20: { id: 20, string: "20% no agenda meetings / 80% agenda meetings" },
  GOAL_30: { id: 30, string: "30% no agenda meetings / 70% agenda meetings" },
  GOAL_40: { id: 40, string: "40% no agenda meetings / 60% agenda meetings" },
  GOAL_50: { id: 50, string: "50% no agenda meetings / 50% agenda meetings" },
  GOAL_60: { id: 60, string: "60% no agenda meetings / 40% agenda meetings" },
  GOAL_70: { id: 70, string: "70% no agenda meetings / 30% agenda meetings" },
  GOAL_80: { id: 80, string: "80% no agenda meetings / 20% agenda meetings" },
  GOAL_90: { id: 90, string: "90% no agenda meetings / 10% agenda meetings" },
};

export const LONG_MEETINGS_GOAL_OPTIONS = {
  GOAL_10: { id: 10, string: "10% long meetings / 90% short meetings" },
  GOAL_20: { id: 20, string: "20% long meetings / 80% short meetings" },
  GOAL_30: { id: 30, string: "30% long meetings / 70% short meetings" },
  GOAL_40: { id: 40, string: "40% long meetings / 60% short meetings" },
  GOAL_50: { id: 50, string: "50% long meetings / 50% short meetings" },
  GOAL_60: { id: 60, string: "60% long meetings / 40% short meetings" },
  GOAL_70: { id: 70, string: "70% long meetings / 30% short meetings" },
  GOAL_80: { id: 80, string: "80% long meetings / 20% short meetings" },
  GOAL_90: { id: 90, string: "90% long meetings / 10% short meetings" },
};

export const BIG_MEETINGS_GOAL_OPTIONS = {
  GOAL_10: { id: 10, string: "10% big meetings / 90% small meetings" },
  GOAL_20: { id: 20, string: "20% big meetings / 80% small meetings" },
  GOAL_30: { id: 30, string: "30% big meetings / 70% small meetings" },
  GOAL_40: { id: 40, string: "40% big meetings / 60% small meetings" },
  GOAL_50: { id: 50, string: "50% big meetings / 50% small meetings" },
  GOAL_60: { id: 60, string: "60% big meetings / 40% small meetings" },
  GOAL_70: { id: 70, string: "70% big meetings / 30% small meetings" },
  GOAL_80: { id: 80, string: "80% big meetings / 20% small meetings" },
  GOAL_90: { id: 90, string: "90% big meetings / 10% small meetings" },
};

export const FOCUS_TIME_GOAL_OPTIONS = {
  GOAL_10: { id: 10, string: "10% focus times / 90% other free times" },
  GOAL_20: { id: 20, string: "20% focus times / 80% other free times" },
  GOAL_30: { id: 30, string: "30% focus times / 70% other free times" },
  GOAL_40: { id: 40, string: "40% focus times / 60% other free times" },
  GOAL_50: { id: 50, string: "50% focus times / 50% other free times" },
  GOAL_60: { id: 60, string: "60% focus times / 40% other free times" },
  GOAL_70: { id: 70, string: "70% focus times / 30% other free times" },
  GOAL_80: { id: 80, string: "80% focus times / 20% other free times" },
  GOAL_90: { id: 90, string: "90% focus times / 10% other free times" },
};

export const EXCESSIVE_BLOCKS_GOAL_OPTIONS = {
  GOAL_10: { id: 10, string: "10% excessive blocks / 90% other meeting times" },
  GOAL_20: { id: 20, string: "20% excessive blocks / 80% other meeting times" },
  GOAL_30: { id: 30, string: "30% excessive blocks / 70% other meeting times" },
  GOAL_40: { id: 40, string: "40% excessive blocks / 60% other meeting times" },
  GOAL_50: { id: 50, string: "50% excessive blocks / 50% other meeting times" },
  GOAL_60: { id: 60, string: "60% excessive blocks / 40% other meeting times" },
  GOAL_70: { id: 70, string: "70% excessive blocks / 30% other meeting times" },
  GOAL_80: { id: 80, string: "80% excessive blocks / 20% other meeting times" },
  GOAL_90: { id: 90, string: "90% excessive blocks / 10% other meeting times" },
};

export const REPORT_RANGE_OPTIONS = {
  /* The string values are what's saved in the DB. 
     Changing the string values will break things. */
  LAST_WEEK: "Last Week",
  THIS_WEEK: "This Week",
  NEXT_WEEK: "Next Week",
  LAST_MONTH: "Last Month",
  THIS_MONTH: "This Month",
  NEXT_MONTH: "Next Month",
  LAST_QUARTER: "Last Quarter",
  THIS_QUARTER: "This Quarter",
  NEXT_QUARTER: "Next Quarter",
  CUSTOM: "Custom",
};
