import React from "react";
import loader from "../assets/loader.gif";
import loader2x from "../assets/loader@2x.gif";
import loader3x from "../assets/loader@3x.gif";
import classNames from "classnames";

function Loader({ bounce = false, loadingText = null }) {
  return (
    <React.Fragment>
      <div className="place-self-center">
        <div
          className={classNames({
            "animate-bounce": bounce,
          })}
        >
          <div className="flex justify-center">
            <img className="" src={loader} srcSet={loader + " 1x," + loader2x + " 2x, " + loader3x + " 3x"} />
          </div>
        </div>
        {loadingText && (
          <div className="pb-36 pt-4">
            <div className="relative inline-flex">
              <div className="absolute opacity-50 -inset-px bg-gradient-to-r from-[#44BCFF] via-[#FF44EC] to-[#FF675E] rounded-xl blur-xl filter"></div>
              <div className="relative animate-pulse font-bold">{loadingText}</div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default Loader;
